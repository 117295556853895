.navbar-header {
  padding-left: 30px;
}

li {
  padding: 8px 8px 8px 32px;

  &:hover {
    background-color: rgb(240, 240, 240);
  }

  .most-played {
    font-size: 11px;
    font-weight: bold;
    color: #279c2c;
    margin-left: 10px;
    margin-top: 1px;
  }
}
