.landing{
  &-header{
    animation: growAndBounce 1s ease-out forwards;
    h1{
      font-size: 60px;
    }
    .logo{
      width: 45px;
      visibility: hidden;
      animation: fadeDown 0.3s 1.3s  ease-out forwards;
    }
  }

  .landing-buttons{
    visibility: hidden;
    margin-top: -10px;
    animation: fadeIn 0.2s 1s ease-out forwards;

    @media(max-width: 400px){
      flex-direction: column-reverse;

      button{
        &:first-child{
          margin-top: 10px;
        }
      }
    }

    button{
      background: white;
      border: 1px solid #1486d0;
      height: 42px;
      padding:10px 20px;
      border-radius: 50px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      margin: 0px 6px;

      &:hover{
        box-shadow: 0 1px 0;
        cursor: pointer;
      }
    }

    .new-label{
      position: absolute;
      background: #fff147;
      color: black;
      border-radius: 10px;
      padding: 3px 9px;
      font-size: 13px;
      top: -7px;
      right: -6px;
      visibility: hidden;
      animation: fadeIn 0.2s 2s ease-out forwards;
    }
  }
}

@keyframes growAndBounce {
  0% {
      transform: scale(0.6);
  }
  40% {
      transform: scale(1.3);
  }
  60% {
    transform: scale(1);
  }
  75%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(-30px);
  }
}

@keyframes fadeIn {
  0% {
    visibility: visible;
    opacity: 0;
    transform: scale(0);
  }
  75% {
    transform: scale(1.1);
    visibility: visible;
    opacity: 1;
  }
  100%{
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeDown {
  0% {
    transform: translateY(-1000px) rotate(-10deg);
    visibility: visible;
}
90% {
    transform: translateY(0) rotate(-20deg);
    visibility: visible;
}
100% {
    transform: translateY(0) rotate(0deg);
    visibility: visible;
}
}