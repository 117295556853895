#operations{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  margin: auto auto 10px;
  display: flex;
}

#operations p {
  font-size: 54px;
}

#operations button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
}

#undo{
  width: 55px;
  height: 55px;
  color: #fff;
  cursor: pointer;
  background: var(--primary-blue);
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 45px;
  transition: filter .3s;
  display: flex;
}

.operation{
  width: 55px;
  height: 55px;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background: #000;
  border: none;
  border-radius: 50%;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 45px;
  transition: filter .3s;
  display: flex;
}

.operation:hover{
  background:var(--primary-blue);
}
