:root {
  --white: #fff;
  --primary-blue: #2da4f2;
}

html{
  scroll-behavior: smooth
}

.App {
  text-align: center;
}

h2 {
  font-size: 52px;
  font-weight: 800;
  margin-bottom: 12px;
  margin-top: 0px;
}

p{
  margin: 0px;
}

.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

.game {
  display: flex;
  flex-direction: column;
}

.game-header {
  min-height: 1px;
  flex: 1 1;
  padding: 12px 24px 4px 24px;
  width: auto;
  border-bottom: 1px solid #dedede;
}

.game-header-container{
  text-align: left;
}

@media(max-width: 700px){

  .game-header{
    padding-bottom: 10px;
  }
  .game-header-container div {
    flex-direction: column;
    align-items: self-start;
    margin-bottom: 4px;
  }
}

@media(max-width: 550px){
  .game-header-container-description {
    display: none;
  }
}

@media(max-width: 550px){
  .tab-items{
    margin-bottom: 0px !important;
  }
}

@media(max-width: 420px){

  .game-header {
    justify-content: space-between !important;
  }

  .tab-items p{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}


.game-header-container-description{
  margin-top: 4px;
  margin-bottom: 0px;
}

.game-header .date{
  font-size: 23px;
  font-weight: 300;
  margin: 0px;
  margin-top: 5px;
}

h1 {
  margin: 0px;
  font-size: 31px;
  font-weight: 900;
}

.game-wrapper {
  display: flex;
  padding: 20px;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.game-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 7vw 2vw;
  display: flex;
  width: 35%;
  padding: 0 1vw 2vw 7vw;
}

.game-status {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.submit-button{
  text-align: center;
  max-height: 4.5em;
  padding: 0px 20px;
  background-color: #dedede;
  color: #7f7d7d;
  height: 46px;
  display: flex;
  border-radius: 37px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  margin-top: 15px;
  align-items: center;
}

.submit-button-active{
  background-color: #fde456;
  color: #000;
  cursor: pointer;
}

.submit-button-active:hover{
  background-color: #f4d734;
}

.error{
  font-weight: 800;
  color: #1a88d2;
  animation: shaking 0.4s 1 ease-in;
}

.close-button{
  position: absolute;
  right: 19px;
  cursor: pointer;
}

@keyframes shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }


/* Utility */


.inline-display{
  display: inline;
}

.flex-row{
  display: flex;
  align-items: center;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.align-start{
  align-items: flex-start !important;
}

.align-end{
  align-items: flex-end !important;
}

.align-center{
  align-items: center;
}

.align-self-start{
  align-self: flex-start;
}

.justify-between{
  justify-content: space-between;
}

.justify-end{
  justify-content: flex-end;
}

.justify-center{
  justify-content: center;
}

.justify-start{
  justify-content: flex-start;
}

.justify-evenly{
  justify-content: space-evenly;
}

.pos-relative{
  position: relative;
}

.pos-absolute{
  position: absolute;
}

.bottom-0{
  bottom: 0px;
}

.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

.w-fit{
  width: fit-content;
}

.h-100{
  height: 100%;
}

.vh-100{
  height: 100vh;
}

.h-100-mobile{
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.italic{
  font-style: italic;
}

.margin-center{
  margin: 0 auto;
}

.mr-0{
  margin-right: 0px;
}

.ml-0{
  margin-left: 0px;
}

.mt-0{
  margin-top: 0px;
}

.mb-0{
  margin-bottom: 0px;
}

.ml-3{
  margin-left: 3px;
}

.mt-5{
  margin-top: 5px;
}

.mr-5{
  margin-right: 5px;
}

.mb-5{
  margin-bottom: 5px;
}

.ml-5{
  margin-left: 5px;
}

.mr-8{
  margin-right: 8px;
}

.mb-8{
  margin-bottom: 8px;
}

.mt-8{
  margin-top: 8px;
}

.mr-10{
  margin-right: 10px;
}

.ml-10{
  margin-left: 10px;
}

.mt-10{
  margin-top: 10px;
}

.mb-10{
  margin-bottom: 10px;
}

.mb-12{
  margin-bottom: 12px;
}

.mr-12{
  margin-right: 12px;
}

.ml-12{
  margin-left: 12px;
}

.mr-15{
  margin-right: 15px;
}

.ml-15{
  margin-left: 15px;
}

.mt-15{
  margin-top: 15px;
}

.mb-15{
  margin-bottom: 15px;
}

.mb-17{
  margin-bottom: 17px;
}

.mt-20{
  margin-top: 20px;
}

.mr-20{
  margin-right: 20px;
}

.mb-20{
  margin-bottom: 20px;
}

.mr-25{
  margin-right: 25px;
}

.ml-25{
  margin-left: 25px;
}

.mt-25{
  margin-top: 25px;
}

.mb-25{
  margin-bottom: 25px;
}

.mt-30{
  margin-top: 30px;
}

.mb-30{
  margin-bottom: 30px;
}

.ml-32{
  margin-left: 32px;
}

.mt-32{
  margin-top: 32px;
}

.mb-32{
  margin-bottom: 32px;
}

.mt-35{
  margin-top: 35px;
}

.mb-35{
  margin-bottom: 35px;
}

.ml-35{
  margin-left: 35px;
}

.mr-35{
  margin-right: 35px;
}

.ml-40{
  margin-left:40px;
}

.mr-40{
  margin-right:40px;
}

.mt-50{
  margin-top: 50px;
}

.ml-85{
  margin-left: 85px;
}

.mr-96{
  margin-right: 96px;
}


.pr-0{
  padding-right: 0px;
}

.pl-5{
  padding-left: 5px !important;
}

.ptb-5{
  padding-top: 5px;
  padding-bottom: 5px;
}

.plr-5{
  padding-left: 5px;
  padding-right: 5px;
}

.plr-6{
  padding-left: 6px;
  padding-right: 6px;
}

.pl-6{
  padding-left: 6px;
}


.pr-7{
  padding-right: 7px;
}

.pt-7{
  padding-top: 7px;
}

.ptb-7{
  padding-top: 7px;
  padding-bottom: 7px;
}

.plr-8{
  padding-left: 8px;
  padding-right: 8px;
}

.pt-10{
  padding-top: 10px;
}

.pb-10{
  padding-bottom: 10px;
}

.pl-10{
  padding-left: 10px;
}

.pr-10{
  padding-right: 10px;
}

.p-12{
  padding: 12px;
}

.pt-12{
  padding-top: 12px;
}

.pb-12{
  padding-bottom: 12px;
}

.prl-12{
  padding-right: 12px;
  padding-left: 12px;
}

.p-14{
  padding: 14px;
}

.ptb-14{
  padding-top: 14px;
  padding-bottom: 14px;
}

.p-15{
  padding: 15px;
}

.pb-15{
  padding-bottom: 15px;   
}

.ptb-20{
  padding-top: 20px;
  padding-bottom: 20px;
}

.pr-20{
  padding-right: 20px;
}

.prl-24{
  padding-left: 24px;
  padding-right: 24px;
}

.pt-25{
  padding-top: 25px;
}

.pb-30{
  padding-bottom: 30px;
}

.pt-30{
  padding-top: 30px;
}

.p-32{
  padding: 32px;
}

.pb-32{
  padding-bottom: 32px;
}

.pt-32{
  padding-top: 32px;
}

.p-40{
  padding: 40px;
}

.pb-40{
  padding-bottom: 40px;   
}

.pb-55{
  padding-bottom: 55px;   
}

.pt-45{
  padding-top: 45px;
}

.plr-50{
  padding-right: 50px;
  padding-left: 50px;
}

.f35{
  font-size: 35px;
}

.f26{
  font-size: 26px;
}

.f24{
  font-size: 24px;
}

.f23{
  font-size: 23px;
}

.f20{
  font-size: 20px;
}

.f18{
  font-size: 18px;
}

.f16{
  font-size: 16px;
  line-height: 24px;
}

.f15{
  font-size: 15px;
}

.f14{
  font-size: 14px;
}

.f12{
  font-size: 13px;
}

.f12{
  font-size: 12px;
}

.f11{
  font-size: 11px;
}

.fw-100{
  font-weight: 100;
}

.fw-500{
  font-weight: 500;
}

.fw-900 {
  font-weight: 900;
}

.no-style-ul {
  list-style-type: none;
  padding: 0;
}

.no-style-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
}

.no-underline {
  text-decoration: none;
}

.bold{
  font-weight: 900;
}

.underline{
  text-decoration: underline;
}

.opacity-0{
  opacity: 0;
}

.opacity-100{
  opacity: 1;
}

.pointer{
  cursor: pointer;
}

.cursor-default{
  cursor: default
}

.events-none{
  pointer-events: none;
}

.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}

.blur{
  filter: blur(11px);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.overflow-hidden{
  overflow: hidden;
}

.overflow-x-hidden{
  overflow-x: hidden;
}

.overflow-auto{
  overflow: auto;
}

.right-0{
  right: 0;
}

.text-center{
  text-align: center;
}

.b-white{
  background-color: var(--white);
}

.b-blue{
  background-color: var(--primary-blue);
}

.b-transparent{
  background-color: transparent;
}

.c-white{
  color: var(--white);
}

.c-black{
  color: black;
}

.c-primary-blue{
  color: var(--primary-blue);
}

.border-black{
  border: 1px solid black !important;
}

.border-bottom-black{
  border-bottom: 2px solid black;
}

.border-grey{
  border: 1px solid #E3E9EE;
}

.border-bottom-grey{
  border-bottom: 1px solid #E3E9EE;
}

.border-bottom-white{
  border-bottom: 1px solid var(--white);
}


.border-none{
  border: none;
}

.radius-8{
  border-radius: 8px;
}

.radius-12{
  border-radius: 12px;
}

.radius-16{
  border-radius: 16px;
}

.radius-50{
  border-radius: 50px;
}

.radius-100{
  border-radius: 100px;
}

@media(max-width: 980px){

  .game-header{
    width: auto;
  }
  .game-wrapper{
    padding-top: 0px;
    width: auto;
  }
  .game-container{
    width: 100%;
    padding: 0px;
  }
}
@media(max-width: 520px){
  h1{
    font-size: 26px;
  }

  .game-header{
    align-items: flex-start;
  }

  .game-header-container{
    flex-direction: column;
    align-items: flex-end;
  }

  .date{
    font-size: 18px;
    font-size: 15px !important;
  }
}
