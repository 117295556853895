#rank-bar-desktop {
  vertical-align: baseline;
  min-height: 1px;
  align-items: center;
  margin: 0 52px 8px;
  display: flex;
  width: 100%;
  max-width: 386px;
}

.toolbar-button {
  color: #000;
  border: none;
  padding: 6px 4px;
  font-size: 14px;
  font-weight: 900;
}
@media (width >= 1024px) {
  .toolbar-button {
    padding: 6px 6px 4px;
    line-height: 45px;
  }
}

#rank-text-mobile, #rank-text-desktop {
  width: 84px;
  max-width: 80px;
  padding: 6px 4px 4px;
  line-height: 28px;
}

#rank-bar-container-mobile, #rank-bar-container-desktop {
  height: 1.875em;
  flex-grow: 1;
  align-items: center;
  margin: 0 12px;
  display: flex;
  position: relative;
  width: 284px;
}

#rank-bar-line-mobile, #rank-bar-line-desktop {
  height: 3px;
  width: 100%;
  background: #dcdcdc;
  align-items: center;
  margin: 0 6px;
  display: flex;
  position: relative;
}

#rank-bar-line-mobile-completion, #rank-bar-line-desktop-completion {
  height: 3px;
  background: var(--primary-blue);
  z-index: 1;
  align-items: center;
  display: flex;
  position: relative;
}

#rank-bar-stars-mobile, #rank-bar-stars-desktop {
  width: 100%;
  z-index: 3;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
}

.progress-filled {
  background-color: var(--primary-blue) !important;
}

#progress-star {
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.progress-star {
  width: 28px;
  height: 28px;
  z-index: 1;
}

#progress-star-text {
  color: #000;
  text-align: center;
  z-index: 2;
  margin-top: 4px;
  font-size: 12px;
  position: absolute;
}

.progress-invisible {
  background-color: #0000;
}

.progress-empty {
  width: 10px;
  height: 10px;
  background-color: #dcdcdc;
  border-radius: 100%;
}
