.ranking-container p{
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
}

.ranking-container ul{
  width: 100%;
  list-style: none;
  padding-left: 0px;
}

.ranking-container ul li{
  padding-bottom: 6px;
}