.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-width: 520px;
  width: 100%;
  background: white;
  margin: 0 auto;
  max-height: 589px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
  overflow: scroll;
}

.modal-content-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content h3 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 12px;
  margin-top: 5px;
}

.modal-history,
.details-history {
  flex-direction: column;
  margin-block-start: unset;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style-type: none;
  display: flex;
  overflow: clip;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.modal-history-entry{
  margin-bottom: 3px;
  padding: 8px !important;
}

.details-history {
  margin-top: 40px;
}

.details-entry {
  margin-bottom: 6px;
}

.modal-stars {
  display: flex;
  align-items: center;
}

.modal-stars svg:nth-child(2) {
  margin-left: -18px !important;
}
.modal-stars svg:nth-child(3) {
  margin-left: -18px !important;
}

.modal-button {
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 38px;
  font-size: 18px;
  background: white;
  font-weight: 800;
  cursor: pointer;
  margin-top: 20px;
  color: black;
}

.modal-button-black{
  background: black !important;
  color: white !important;
}

.modal-button:hover{
  background: black;
  color: white;
}
