.tabs{
  border-bottom: 1px solid gainsboro;
  padding: 7px 20px 0px 24px;
}

@media(max-width: 520px){
  .tabs{
    padding: 0px;
  }
  .tabs-container div{
    width: 100% !important;
  }
  .tabs .tab:nth-of-type(5) {
    border-right: none;
  }
  .tab{
    border-radius: 0px !important;
  }
}

.tabs-container{
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  align-items: flex-end;
}

.tabs .tab{
  width: 68px;
  height: 46px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #c5bfbf;
  text-align: center;
  font-size: 20px;
  border-left: none;
  background-color: #f3f3f3;
  border-right: 1px solid #c6c6c6;
  border-top: 1px solid #c6c6c6;
  border-bottom: none;
  font-weight: bold;
  cursor: pointer;
}

.tab .stars{
  width: auto !important;
}

.tabs .tab:nth-of-type(1){
  border-left: 1px solid #c6c6c6;
  border-top-left-radius: 4px;;
}
.tabs .tab:nth-of-type(5){
  border-top-right-radius: 4px;;
}

.tabs .tab-active{
  background: white;
  color: black;
}