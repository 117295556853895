.numbers{
  width: 275px;
  height: 176px;
  margin: auto auto 30px;
  position: relative;
}

.number{
  background: white;
  cursor: pointer;
  text-align: center;
  width: 86px;
  height: 86px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border: 3px dashed #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 32px;
  display: flex;
  position: absolute;
  font-weight: bold;
  transition: top 0.2s, left 0.2s;
  color: black;
}

.item-active{
  background: var(--primary-blue) !important;
  color: white;
  border: none;
  z-index: 99;
  animation: shrink 0.1s ease-out;
}

.item-empty{
  border: none;
  opacity: 0;
}

.new-item-active{
  animation: expand 0.4s ease-out;
}

#number-0{
  top: 0;
  left: 0;
}
#number-1{
  top: 0;
  left: calc(50% - calc(86px/2))
}
#number-2{
  top: 0;
  left: calc(100% - 86px);
}
#number-3{
  top: calc(100% - 86px);
  left: 0;
}
#number-4{
  top: calc(100% - 86px);
  left: calc(50% - calc(86px/2))
}
#number-5{
  top: calc(100% - 86px);
  left: calc(100% - 86px);
}

.start-button{
  position: absolute;
  z-index: 1;
  font-size: 24px;
  cursor: pointer;
  border: none;
  padding: 12px 33px;
  border-radius: 34px;
  background: #2ba4f2;
  color: white;
  font-weight: bold;
  margin-top: -70px;
}

.start-button:hover{
  background: #208dd5;
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.93);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
