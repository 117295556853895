#game-status-desktop {
  min-width: 376px;
  margin-left: 54px;
}

#history, #ads{
  width: 50%;
}

#ads{
  background: grey;
  max-width: 250px;
}

#history{
  vertical-align: baseline;
  border: 1px solid #c7c7c7;
  letter-spacing: .005em;
  height: 375px;
  border-radius: 12px;
  flex-direction: row;
  margin-right: 18px;
  display: flex;
  max-width: 390px;
  width: 100%;
}

#history-container-desktop {
  flex: auto;
  flex-basis: fit-content;
  height: 100%;
  display: flex;
  position: relative;
}

.history-desktop {
  color: #000;
  flex-direction: column;
  margin-block-start: unset;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style-type: none;
  display: flex;
  overflow: clip;
  margin-top: 14px;
  width: 100%;
  max-width: 139px;
  text-align: left;
}

.history-desktop>li.history-entry, #solution-desktop>li.solution-entry {
  width: 100%;
  min-width: 160px;
  border-bottom: 1px solid #000;
  align-items: center;
  margin-bottom: 7px;
  margin-right: 20px;
  padding: 4px 0 4px 1px;
  display: flex;
}

.history-container-content{
  padding: 24px;
  align-items: flex-start
}

.solution-container{
  padding-left: 0px;
  min-width: 145px;
}

.solution-container .history-entry {
  border-bottom: 1px solid #000;
  padding: 2px 0 4px 1px;
}