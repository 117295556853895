.MuiDrawer-paper {
  margin-top: 0px !important;
  border-radius: 0px !important;
  padding: 32px 0px !important;
  max-width: 343px;
  width: 100%;

  .close-drawer-icon {
    right: 30px;
  }
}

.MuiBackdrop-root {
  background-color: transparent;
}
