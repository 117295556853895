.promo-banner{
  // padding: 40px 10px;
  // background-color: #d49cff;
  // background: #d49cff;
  // border-bottom: 1px solid black;
  padding: 10px;
  background-color: #d49cff;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;

  @media(max-width: 450px){
    h2{
      font-size: 22px !important;
    }

    p{
      font-size: 14px;
    }

    button{
      font-size: 16px;
    }
  }

  h2{
    font-size: 30px;
    margin-bottom: 0px;
    line-height: 30px;
    margin-right: 10px;
    font-weight: 900;
  }

  p{
    margin-top: 8px;
  }

  button{
    min-width: 109px;
    margin-left: 10px;
    border-radius: 50px;
    background-color: black;
    color: white;
    font-size: 14px;
    padding: 10px 25px;
    border: none;
  }
}