.history-drawer{
  width: 94%;
  z-index: 999;
  height: 7vh;
  margin: 12px auto;
  margin-bottom: 0px;
  position: relative;
  cursor: pointer;
  margin-bottom: 17px;
}

#game-status-mobile{
  width: 100%;
  height: 68vh;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  transition-duration: .5s;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 15px;
}

.collapsed {
  height: 45px !important;
}