.rules-container{
  padding: 0px 25px;
}

.rules-container h3{
  margin-bottom: 0px;
}

.rules-container p{
  font-weight: 500;
}

.rules-container img{
  width: 200px;
}

.rules-container ul{
  margin-top: 20px;
  width: 100%;
}

.rules-container li{
  margin-bottom: 7px;
  font-weight: 500;
}