.faqs{
  border-top: 1px solid #d6d6d6;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
}

.faqs h2{
  font-size: 20px;
}

.faqs p{
  margin-bottom: 30px;
}

.faqs h3 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 25px;
}



